<template>
  <div>
    <v-card>
      <v-card-title class="text-h6">{{ $t('labels.indemnify') }}</v-card-title>
      <v-card-text>{{ $t('labels.form_sub_text') }}</v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <vuetify-money
                class="c-input-xs" v-model="money_collected"
                type="number" :label="$t('labels.indemnify_money_collected')"
                :options="moneyOptions" dense outlined clearable hide-details
            />
          </v-col>

          <v-col cols="12">
            <vuetify-money
                class="c-input-xs" v-model="amount"
                type="number" :label="$t('labels.indemnify_amount')"
                :options="moneyOptions" dense outlined clearable hide-details
            />
          </v-col>

          <v-col cols="12">
            <v-text-field class="c-input-xs" v-model="note"
                          type="text" :label="$t('labels.note')"
                          dense outlined clearable hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          {{ $t('labels.cancel') }}
        </v-btn>
        <v-btn color="green darken-1" text @click="confirm" v-if="money_collected && amount && note">
          {{ $t('labels.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {httpClient} from "@/libs/http";

export default {
  name: "Indemnify",
  components: {
  },
  props: {
    orderId: {
      type: [String, Number],
      default: () => null
    },
  },
  data: () => ({
    isLoading: false,
    money_collected: null,
    amount: null,
    note: null,
    moneyOptions: {
      length: 11,
      precision: 0
    }
  }),
  mounted() {

  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        await httpClient.post('/order-online-indemnify', {
          id: this.orderId,
          money_collected: this.money_collected,
          amount: this.amount,
          note: this.note,
        })
        this.isLoading = false
        this.$root.$emit('playSuccessAudio')
        this.$emit('refreshData', this.orderId)
        this.cancel()
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
        this.isLoading = false
        this.$root.$emit('playErrorAudio')
      }
    }
  }
}
</script>

<style scoped>

</style>
